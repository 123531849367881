import React, { Suspense, useLayoutEffect, useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, withRouter, Redirect, Switch } from 'react-router-dom';
import Loader from './components/sections/COMP/Loader.jsx'
import axios from 'axios'
import swal from "sweetalert2";
import TopMenu from './components/layouts/TopMenu.jsx'
import {celieresNavigation} from "../src/celieresNavigation.js"
import Footer from './components/layouts/Footer.js';

//Maison Célières
const Accueil = React.lazy(() => import("./components/pages/Accueil/Accueil.jsx"));
const Contact = React.lazy(() => import("./components/pages/Contact/Contact.jsx"));
const EventList = React.lazy(() => import("./components/pages/Rubriques/EventList.jsx"));
const EventDetails = React.lazy(() => import("./components/pages/Rubriques/EventDetails.jsx"));
const FilmMuet = React.lazy(() => import("./components/pages/Rubriques/FilmMuet/FilmMuet.jsx"));
const Error = React.lazy(() => import("./components/pages/Error.jsx"));
const FamilleCelieres = React.lazy(() => import("./components/pages/Rubriques/FamilleCelieres/FamilleCelieres.jsx"));


// Scroll to Top
const ScrollToTop = withRouter(({ children, location: { pathname } }) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return children || null
})

function useAxiosInterceptor() {
  useEffect(() => {
    // Ajouter un intercepteur pour les requêtes sortantes
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        // Ajouter un header d'autorisation à chaque requête sortante
        const token = localStorage.getItem('jwt_token');
        config.headers.Authorization = "Bearer " + token;
        return config;
      }
    );

    // Ajouter un intercepteur pour les réponses entrantes
    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        if (response.data.token) {
          localStorage.setItem('jwt_token', response.data.token);
        }
        return response;
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          swal.fire({
            title: "Votre session a expiré",
            icon: 'error',
            showConfirmButton: false,
            allowOutsideClick: false,
          });
          setTimeout(() => {
            window.location.href = '/login';
          }, 2000);
        }
        return Promise.reject(error);
      }
    );

    // Nettoyer les intercepteurs lors du démontage du composant
    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);
}

function App() {
  useAxiosInterceptor();
  
  function GuardAuth({ children }) {
    const [loading,setLoading]=useState(true);
    const [isAuth,setIsAuth]=useState(false);
    const uninterceptedAxiosInstance = axios.create();

    useEffect(function () {
      (async function() {
          const response = await uninterceptedAxiosInstance.get(process.env.REACT_APP_API_URL+"api/auth/me",{headers: {'Authorization': 'Bearer ' + localStorage.getItem("jwt_token")}}
          ).then((response) => {
            setIsAuth(true)
            setLoading(false)
          }).catch((error) => {
            setIsAuth(false)
            setLoading(false)
          })
      })()
    }, [])

    if(loading){
      return (
        <Loader></Loader>
      )
    }
    else{
      if(isAuth){
        return (
          <>
            {children}
          </>
       );
      }
      else{
        return(
          <Redirect to="/" />
        )
      }
    }
  }

  return (
    <Router>
      <Suspense fallback={<div></div>}>
        <ScrollToTop>
          <TopMenu menu={celieresNavigation}/>
            <Switch>
            
              {/* Home */}
              <Route exact path="/" component={Accueil} />
              <Route exact path="/contact" component={Contact} />
              <Route exact path="/rubrique/:rubriqueName/:id" component={EventList} />
              <Route exact path="/rubrique/:rubriqueName/:id/:idEvent/:eventName" component={EventDetails} />
              <Route exact path="/rubrique/:rubriqueName/:id/:idEvent/:eventName" component={EventDetails} />

              <Route exact path="/rubrique/famille-celieres" component={FamilleCelieres} />
              <Route exact path="/rubrique/films-muets-annees-30" component={FilmMuet} />
              <Route path="" component={Error} />
              <GuardAuth>

              </GuardAuth>

             
            </Switch>
          <Footer />
        </ScrollToTop>
      </Suspense>
    </Router>
  );
}

export default App;
