import React from "react";

const styles = {
  position: "fixed",
  left: 0,
  right: 0,
  top: "calc(50% - 20px)",
  margin: "auto",
  height: "40px",
  width: "40px",
  zIndex: 324324324
  
  // margin: 'auto',
  // height: '100vh',
  // width: '40px',
  // marginTop: '20%',
};

const Loader = () => {
  return (
    <div style={styles}>
        <span className="loader" role="status"></span>
    </div>
  );
};

export default Loader;
