import React, { Component, Fragment } from 'react';
import instagram from '../../data/instagram.json';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import logomc2 from '../../assets/img/logomc2.png';

const settings = {
    slidesPerView: 2,
    loop: true,
    autoplay: {
        delay: 2500,
        disableOnInteraction: false,
    },
    navigation: false,
    breakpoints: {
        480: {
            slidesPerView: 3,
        },
        768: {
            slidesPerView: 4,
        },
        992: {
            slidesPerView: 6,
        },
        1500: {
            slidesPerView: 8,
        },
    }
};

const Footer = () => {

    return (
        <Fragment>
            <footer className="section-padding bg-light-theme pt-0 u-line bg-custom-primary">
                <div className="container-fluid custom-container">
                    <div className="row">
                        <div className="col-xl col-lg-4 col-md-4 col-sm-6">
                            <div className="footer-contact">
                                <div className="logo mb-xl-20">
                                    <Link to="#">
                                        <img src={logomc2} className="img-fluid" alt="logo maison célières" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl col-lg-4 col-md-4 col-sm-6">
                            <div className="footer-links">
                                <ul>
                                    <li><Link to="/rubrique/programmation-mensuelle/2" className="text-white fw-500">Programmation mensuelle</Link>
                                    </li>
                                    <li><Link to="/rubrique/offres-culturelles/3" className="text-white fw-500">Offres culturelles</Link>
                                    </li>
                                    <li><Link to="/blog-details/1" className="text-white fw-500">Visite Maison Célières</Link>
                                    </li>
                                    <li><Link to="/donation-details/1" className="text-white fw-500">Parcours du Faubourg</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl col-lg-4 col-md-4 col-sm-6">
                            <div className="footer-links">
                                <ul>
                                    <li><Link to="/login" className="text-white fw-500">Les jardins de nos Grands Mères</Link>
                                    </li>
                                    <li><Link to="/rubrique/famille-celieres" className="text-white fw-500">Famille Célières</Link>
                                    </li>
                                    <li><Link to="/rubrique/films-muets-annees-30" className="text-white fw-500">Film muet années 30</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl col-lg-4 col-md-4 col-sm-6">
                            <div className="footer-links">
                                <ul className="contact-info">
                                    <li>
                                        <div className="text-white d-flex align-items-center">
                                            <i className="pe-7s-timer" />&nbsp;&nbsp;&nbsp;&nbsp;
                                            <span>Lundi - Vendredi: 8:00 - 12:00 / 13:00 - 17:00 <br/> Samedi: 8:00 - 11:30 </span>
                                        </div>
                                    </li>
                                    <li>
                                        <Link to="/contact" className="text-white"> <span><i className="pe-7s-mail" /></span>
                                        Ecrivez-nous</Link>
                                    </li>
                                    <li>
                                        <span className="text-white"><span><i className="pe-7s-call" />&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                        (+687) 77 83 73</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div className="copyright bg-black">
                <div className="container-fluid custom-container">
                    <div className="row d-flex justify-content-center">
                        <div>
                            <div className="copyright-text"> <span className="text-white">© <span className="text-white ">La Maison Célières</span> - 2023 | Réalisation <a href="https://www.ciweb.nc" target="_blank" style={{fontFamily:"Paytone One, sans-serif", color:'white'}}>Ciweb</a></span></div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Footer;