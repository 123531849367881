import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import logomc from '../../assets/img/logomc.png';

const TopMenu = (props) => {
    const [togleMenu,setTogleMenu]=useState("main-navigation") 
    const [isTop,setIsTop]=useState('') 
    const [isSubMenuVisible, setSubMenuVisible] = useState({
        Rubriques:false,
    });

    useEffect(function () {
        window.addEventListener('scroll', () => {
            if(window.scrollY > 100){
                setIsTop('sticky')
            }else{
                setIsTop('')
            }
        }, false);
    }, [])

    const handleMouseEnter = (menu) => {
        setSubMenuVisible({...isSubMenuVisible,[menu]:true});
    };

    const handleMouseLeave = (menu) => {
        setSubMenuVisible({...isSubMenuVisible,[menu]:false});
    };

    const handleClickMenu = (menu) => {
        setSubMenuVisible({...isSubMenuVisible,[menu]:!isSubMenuVisible[menu]});
    };

    const togleMenuBurger = () =>{
        if(togleMenu==='main-navigation'){
            setTogleMenu('main-navigation active')
        }else{
            setTogleMenu('main-navigation')
        }
    }
    
    return (
        <header className={"header " + isTop}>
            <div className="container-fluid custom-container">
                <div className="row">
                    <div className="col-12">
                        <div className="navigation">
                            <div className="logo">
                            <Link to="/">
                                <img src={logomc} className="image-fit" alt="logo Maison Célières" />
                            </Link>
                            </div>
                            <div className={togleMenu}>
                                <nav>
                                    <ul className="main-menu">
                                    {props.menu.map((item, i) => (
                                        <li key={i} className={`menu-item ${item.child ? 'menu-item-has-children' : ''} `}
                                            onMouseEnter={()=>handleMouseEnter(item.name)}
                                            onMouseLeave={()=>handleMouseLeave(item.name)}
                                            onClick={()=>handleClickMenu(item.name)}
                                        >
                                        {item.child ? 
                                            <Link to="#" className="text-custom-white">{item.name} <span className="arrow" /></Link> 
                                        : 
                                            <Link to={item.path} className="text-custom-white"> {item.name} </Link>}
                                        {item.child ?
                                            isSubMenuVisible[item.name] ? 
                                                <ul className="sub-menu" role="menu">
                                                    {item.submenu.map((sub_item, i) => {
                                                        let urlRedirect = (sub_item.id) ? sub_item.path+sub_item.id : sub_item.path
                                                        
                                                        return(
                                                            <li key={i} className='menu-item'>
                                                                <Link to={urlRedirect}> {sub_item.name}</Link>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            : false
                                        :
                                            null
                                        }
                                        </li>
                                    ))}
                                    </ul>
                                </nav>
                            </div>
                            <div className="right-side-navigation">
                                <ul>
                                    <li className="hamburger-menu">
                                        <Link to="#" className="menu-btn" onClick={()=>togleMenuBurger()}>
                                            <span />
                                            <span />
                                            <span />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default TopMenu;