export const celieresNavigation = [
    {
        name: "Accueil",
        type: "link",
        path: "/",
        child: false
    },
    {
        name: "Rubriques",
        type: "link",
        path: "",
        child: true,
        submenu: [
            {
                id: 2,
                path: "/rubrique/programmation-mensuelle/",
                name: "Programmation mensuelle"
            },
            {
                id: 3,
                path: "/rubrique/offres-culturelles/",
                name: "Offres culturelles"
            },
            {
                id: false,
                path: "#",
                name: "Visite Maison Célières"
            },
            {
                id: false,
                path: "#",
                name: "Parcours du Faubourg"
            },
            {
                id: false,
                path: "#",
                name: "Les jardins de nos Grands Mères"
            },
            {
                id: false,
                path: "/rubrique/famille-celieres",
                name: "Famille Célières"
            },
            {
                id: false,
                path: "/rubrique/films-muets-annees-30",
                name: "Films muets années 30"
            },

        ]
    },
    {
        name: "Contact",
        type: "link",
        path: "/contact",
        child: false
    },
];